<template>
  <ul class="goods-sales">
    <li>
      <p>浏览次数</p>
      <p>{{ goods.pageView }}</p>
      <p><i class="iconfont icon-see"></i>浏览次数</p>
    </li>
    <li>
      <p>下载次数</p>
      <p>{{ goods.downloads }}</p>
      <p><i class="iconfont icon-favorite-filling"></i>下载次数</p>
    </li>
    <!-- <li>
      <p>收藏人气</p>
      <p>600+</p>
      <p><i class="iconfont icon-favorite-filling"></i>收藏商品</p>
    </li>
    <li>
      <p>品牌信息</p>
      <p>苏宁电器</p>
      <p><i class="iconfont icon-dynamic-filling"></i>品牌主页</p>
    </li> -->
  </ul>
</template>

<script>
export default {
  name: "GoodsSales",
  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
};
</script>

<style scoped lang="less">
.goods-sales {
  display: flex;
  width: 400px;
  align-items: center;
  text-align: center;
  height: 140px;
  li {
    flex: 1;
    position: relative;
    ~ li::after {
      position: absolute;
      top: 10px;
      left: 0;
      height: 60px;
      border-left: 1px solid #e4e4e4;
      content: "";
    }
    p {
      &:first-child {
        color: #999;
      }
      &:nth-child(2) {
        color: @priceColor;
        margin-top: 10px;
      }
      &:last-child {
        color: #666;
        margin-top: 10px;
        i {
          color: @xtxColor;
          font-size: 14px;
          margin-right: 2px;
        }
        &:hover {
          color: @xtxColor;
          cursor: pointer;
        }
      }
    }
  }
}
</style>
