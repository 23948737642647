<template>
  <AppLayout>
    <div class="xtx-goods-page">
      <div class="container" v-if="goodsDetail">
        <!-- 面包屑 -->
        <XtxBread>
          <XtxBreadItem path="/">首页</XtxBreadItem>
          <XtxBreadItem :path="`/category/${goodsDetail?.categoryId}`">{{
            goodsDetail?.categoryName
          }}</XtxBreadItem>
          <!-- <XtxBreadItem
            :path="`/category/sub/${goodsDetail?.categories[0].id}`"
            >{{ goodsDetail?.categories[0].name }}</XtxBreadItem
          > -->
          <XtxBreadItem :id="goodsDetail?.id">{{
            goodsDetail?.name
          }}</XtxBreadItem>
        </XtxBread>
        <!-- 商品信息 -->
        <div class="goods-info">
          <!-- 左侧 -->
          <div class="media">
            <GoodsImages :images="[minioURL + '/' + goodsDetail.imageUrl]" />
            <GoodsSales :goods="goodsDetail" />
          </div>
          <!-- 右侧 -->
          <div class="spec">
            <GoodsInfo :goods="goodsDetail" />
            <!-- 商品规格选择 -->
            <!-- <GoodsSku
              :specs="goodsDetail.specs"
              :skus="goodsDetail.skus"
              @onSpecChange="onSpecChange"
            /> -->
            <!-- <XtxNumberBox
              label="数量"
              :max="goodsDetail.inventory"
              v-model="count"
            />
            <XtxButton
              type="primary"
              :style="{ marginTop: '20px' }"
              @click="addCart"
              >加入购物车</XtxButton
            > -->
          </div>
        </div>
        <!-- 商品推荐 -->
        <!-- <GoodsRelevant :goodsId="goodsDetail.id"></GoodsRelevant> -->
        <!-- 商品详情 -->
        <!-- <div class="goods-footer">
          <div class="goods-article"> -->
        <!-- 商品+评价 -->
        <!-- <GoodsTab /> -->
        <!-- 注意事项 -->
        <!-- <div class="goods-warn"> -->
        <!-- 注意事项 -->
        <!-- <GoodsWarn />
            </div>
          </div> -->
        <!-- 24热榜 -->
        <!-- <div class="goods-aside">
            <GoodsHot :type="1" />
            <GoodsHot :type="2" />
            <GoodsHot :type="3" />
          </div> -->
        <!-- </div> -->
      </div>
    </div>
  </AppLayout>
</template>

<script>
import { provide } from "vue";
//import GoodsRelevant from "@/views/goods/components/GoodsRelevant";
import AppLayout from "@/components/AppLayout";
import useGoods from "@/hooks/goods/useGoods";
import GoodsImages from "@/views/goods/components/GoodsImages";
import GoodsSales from "@/views/goods/components/GoodsSales";
import GoodsInfo from "@/views/goods/components/GoodsInfo";
// import GoodsSku from "@/views/goods/components/GoodsSku";
//import GoodsTab from "@/views/goods/components/GoodsTab";
//import GoodsHot from "@/views/goods/components/GoodsHot";
//import GoodsWarn from "@/views/goods/components/GoodsWarn";
const { minioURL } = window.settings;
export default {
  name: "GoodsDetailPage",
  components: {
    //GoodsWarn,
    //GoodsHot,
    //GoodsTab,
    // GoodsSku,
    GoodsInfo,
    GoodsSales,
    GoodsImages,
    //GoodsRelevant,
    AppLayout,
  },
  setup() {
    const { result: goodsDetail } = useGoods();
    // 通过provide 注入goodsDetail数据
    provide("goodsDetail", goodsDetail);

    return { goodsDetail, minioURL };
  },
};
</script>

<style scoped lang="less">
.goods-info {
  min-height: 600px;
  background: #fff;
  display: flex;
  .media {
    width: 580px;
    height: 600px;
    padding: 30px 50px;
  }
  .spec {
    flex: 1;
    padding: 30px 30px 30px 0;
  }
}
.goods-footer {
  display: flex;
  margin-top: 20px;
  .goods-article {
    width: 940px;
    margin-right: 20px;
  }
  .goods-aside {
    width: 280px;
    min-height: 1000px;
  }
}
.goods-tabs {
  min-height: 600px;
  background: #fff;
}
.goods-warn {
  min-height: 600px;
  background: #fff;
  margin-top: 20px;
}
</style>
