/**
 * 格式化字节大小
 * @param 大小
 * @param 保留小数  默认2
 * @returns {string}
 */
export function formatBytesSize(size, decimals) {
  if (0 == size) return "0 B";
  var c = 1024,
    d = decimals || 2,
    e = ["B", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"],
    f = Math.floor(Math.log(size) / Math.log(c));
  return parseFloat((size / Math.pow(c, f)).toFixed(d)) + " " + e[f];
}
