<template>
  <p class="g-name">{{ goods.name }}</p>
  <!-- <p class="g-desc">{{ goods.desc }}</p>
  <p class="g-price">
    <span>{{ goods.price }}</span>
    <span>{{ goods.oldPrice }}</span>
  </p> -->
  <div class="g-service">
    <dl v-for="(item, index) in goods?.modelItemList" :key="index">
      <dt>.{{ item.ext }}</dt>
      <dd>
        <span>{{ formatBytesSize(item.size) }}</span>
        <a href="javascript:;" @click="downloadModelItem_(item)">下载一</a
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="javascript:;" @click="downloadModelItem(item, goods.name)"
          >下载二</a
        >&nbsp;&nbsp;&nbsp;&nbsp;
        <a href="javascript:;" @click="online(item)">在线编辑/预览</a>
      </dd>
    </dl>
    <!-- <dl>
      <dt>促销</dt>
      <dd>12月好物放送，App领券购买直降120元</dd>
    </dl>
    <dl>
      <dt>配送</dt>
      <dd>至 <XtxCity :location="location" @onCityChanged="cityChanged" /></dd>
    </dl>
    <dl>
      <dt>服务</dt>
      <dd>
        <span>无忧退货</span>
        <span>快速退款</span>
        <span>免费包邮</span>
        <a href="javascript:">了解详情</a>
      </dd>
    </dl> -->
  </div>
</template>

<script>
// import XtxCity from "@/components/library/XtxCity";
import { downloadFile } from "@/utils/download";
import { formatBytesSize } from "@/utils/index";
import { downloadSinglelModel, updateDownloads } from "@/api/goods";
import { ref } from "vue";
const { minioURL, onlineViewerURL } = window.settings;
export default {
  name: "GoodsInfo",
  // components: { XtxCity },
  props: {
    goods: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    // 存储配送定制
    const location = ref("");
    const minio = ref(minioURL + "/");
    const viewerUrl = ref(onlineViewerURL);
    const cityChanged = (data) => {
      location.value = data.location;
    };
    const downloadModelItem = (modelItem, name) => {
      downloadSinglelModel(modelItem.id).then((res) => {
        downloadFile(res, `www.your3dmodel.com_${name}`, modelItem.ext);
      });
    };
    const downloadModelItem_ = (modelItem) => {
      updateDownloads(modelItem.modelId);
      window.open("" + minio.value + modelItem.modelUrl);
    };
    const online = (modelItem) => {
      updateDownloads(modelItem.modelId);
      window.open(
        viewerUrl.value + "/#model=" + minio.value + modelItem.modelUrl
      );
    };
    return {
      location,
      cityChanged,
      downloadModelItem,
      downloadModelItem_,
      online,
      formatBytesSize,
    };
  },
};
</script>

<style lang="less" scoped>
.g-name {
  font-size: 22px;
}
.g-desc {
  color: #999;
  margin-top: 10px;
}
.g-price {
  margin-top: 10px;
  span {
    &::before {
      content: "¥";
      font-size: 14px;
    }
    &:first-child {
      color: @priceColor;
      margin-right: 10px;
      font-size: 22px;
    }
    &:last-child {
      color: #999;
      text-decoration: line-through;
      font-size: 16px;
    }
  }
}
.g-service {
  background: #f5f5f5;
  width: 500px;
  padding: 20px 10px 0 10px;
  margin-top: 10px;
  dl {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    dt {
      width: 50px;
      color: #999;
    }
    dd {
      color: #666;
      &:last-child {
        span {
          margin-right: 10px;
          &::before {
            content: "•";
            color: @xtxColor;
            margin-right: 2px;
          }
        }
        a {
          color: @xtxColor;
        }
      }
    }
  }
}
</style>
